const getDomain = () => {
  if (process.env.NODE_ENV === "development")
    return `${window.location.protocol}//${window.location.hostname}:8000`;
  return "https://api.smartdine.in";
};

export const DOMAIN = getDomain();

export const PLAN = {
  BASIC: "BASIC",
  PRO: "PRO",
};

export const PLAN_LEVEL = {
  [PLAN.BASIC]: 1,
  [PLAN.PRO]: 2,
};

export const checkIfSubscribedTo = (restaurantPlan, requiredPlan) => {
  return restaurantPlan
    ? PLAN_LEVEL[restaurantPlan] >= PLAN_LEVEL[requiredPlan]
    : false;
};

const getCurrencyIcon = (currency_code) => {
  if (currency_code === "USD") return "$";
  return "₹";
};

export const getCurrency = (object) => {
  // object must be an organization(object) or a currency_code(string).
  // Supported: INR/USD
  if (typeof object === "object") {
    return getCurrencyIcon(object.currency);
  }
  return getCurrencyIcon(object);
};
